import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const InfluncerMarketingArticle = () => {
  const data = useStaticQuery(graphql`
    query Aricle1Query {
      girl: file(relativePath: { eq: "social-girl.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grow: file(relativePath: { eq: "social-strategy.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phone: file(relativePath: { eq: "influencer-watch.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engage: file(relativePath: { eq: "engage.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      research: file(relativePath: { eq: "research.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      scale: file(relativePath: { eq: "scale.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Influncer Marketing"
        description="Are you wondering what the difference is between micro influencer marketing and influencer marketing? Learn more about it here."
      />
      <div>
        <div className="map px-4 md:px-32 py-32 font-sans">
          <h1 className="text-center ">
            Micro Influencer Marketing: What You Need to Know
          </h1>
          <p className="text-gray-700 font-thinner mt-16">
            Did you know that businesses earn{" "}
            <a
              className="underline text-blue"
              target="_blank"
              href="https://websitebuilder.org/blog/influencer-marketing-statistics/"
            >
              around $5.20
            </a>{" "}
            for every dollar they spend on influencer marketing campaigns? That
            literally means, if you give an influencer a dollar, you will get a
            profit of around $4.20. 
          </p>
          <p className="text-gray-700 font-thinner">
            If you're interested in using influencers for marketing and want
            need more information, keep reading to understand micro-influencer
            marketing compared to influencer marketing and the benefits in
            general of influencer marketing. 
          </p>
        </div>
        <div className=" mx-8 md:mx-12 xl:mx-32">
          <h2>What Is Influencer Marketing? </h2>
          <div className="flex flex-col md:flex-row">
            <div>
              <p>
                Influencer marketing takes influential individuals in various
                niches because of their expertise and uses them to help promote
                a product. This is done through product mentions and
                endorsement. 
              </p>
              <p>
                For example, if you were looking at the realm of gaming, say
                there was an influencer that was popular on YouTube or Twitch
                for streaming various games. A game developer could approach
                them and offer them an endorsement to stream their game. 
              </p>
              <p>
                This works because influencers build trust with their followers
                on <Link to="/pricing#instagram">social media</Link>. When an
                influencer recommends a brand, their audience listens. 
              </p>
              <p>
                Influencers can fall into several categories these categories
                include: 
              </p>
            </div>
            <div className="mx-auto w-2/3">
              <Img
                fluid={data.girl.childImageSharp.fluid}
                alt={`list example`}
              />
            </div>
          </div>
          <ul className="my-8">
            <li style={{ color: "#1bb978" }} className="text-xl font-semibold">
              Mega-Influencers
            </li>
            <li style={{ color: "#00bba8" }} className="text-xl font-semibold">
              Macro-Influencers
            </li>
            <li style={{ color: "#00bad3" }} className="text-xl font-semibold">
              Micro-Influencers
            </li>
            <li style={{ color: "#1aaaff" }} className="text-xl font-semibold">
              Nano-Influencers
            </li>
          </ul>
          <p>
            Numbers can vary when you look at the types of influencers and what
            category they fall in especially depending on the platform. There is
            no exact science in defining the level of influence an influencer
            has. Let's take a closer look into each one.
          </p>
          <h3 className="mt-10 mb-4" style={{ color: "#1bb978" }}>
            What Is a Mega Influencer?
          </h3>
          <p>
            When you are looking at mega-influencers, think big names. These are
            going to be your highest-ranking social media influencers. 
          </p>
          <p>
            They will have more than a million followers. Typically, their
            influence comes from their fame. They have a wide reach because of
            their fame, but the level of interaction with their followers is
            different from what you would see with other influencers. 
          </p>
          <p>
            When you think of a mega-influencer, think of names like Will Smith
            or other A or B List celebrities. 
          </p>
          <h3 className="mt-10 mb-4" style={{ color: "#00bba8" }}>
            What Is a Macro-Influencer?
          </h3>
          <p>
            Macro-influencers still have a pretty big audience. They typically
            have upwards of 100,000 followers but less than a million
            followers. 
          </p>
          <p>
            An excellent example of a macro-influencer is Nick Pitera.{" "}
            <a
              target="_blank"
              className="underline text-blue"
              href="https://lewishowes.com/videos/youtube-celebrity-nick-pitera/"
            >
              Nick Pitera
            </a>{" "}
            has around 836K followers on YouTube. He has made his name by
            producing great content online. 
          </p>
          <p>
            If you are unfamiliar with Nick Pitera, he takes Disney and broadway
            songs and sings every role within the song. 
          </p>
          <h3 className="mt-10 mb-4" style={{ color: "#00bad3" }}>
            What Is a Micro-Influencer? 
          </h3>
          <p>
            You will notice that as we go down the list, the numbers get lower.
            However, as the numbers get lower, you see a higher level of
            influence because the relationship between influencers and followers
            tends to be different. As you have fewer followers, you are more
            likely to be more of an opinion leader. 
          </p>
          <p>
            Micro-influencers are the next level. Your micro-influencers are
            going to have anywhere from 1,000 to 100,000 followers. These
            influences tend to have very niche areas that they focus on and are
            industry experts. 
          </p>
          <p>
            These are names you are not going to hear as often. While your mega
            and macro-influencers are going to be relatively well-known,
            micro-influencers are well known within a smaller community;
            however, they are highly trusted. 
          </p>
          <h3 className="mt-10 mb-4" style={{ color: "#1aaaff" }}>
            What Is a Nano-Influencer? 
          </h3>
          <p>
            Nano-Influencers are not as well known as the aforementioned types
            of influencers. This crew tends to have less than 1,000 followers.
            However, they often have a significant reach within their
            community. 
          </p>
          <p>
            When you utilize nano-influencers, it's about using everyday people
            that can easily be related as an influence for your brand. This
            could be the local paster, local government, or other local voices
            that are well-known. 
          </p>
          <h2 className="text-center text-4xl mt-24">
            What Type of Influencer Should Your Company Use? 
          </h2>
          <div className="mx-auto w-full md:w-1/3">
            <Img fluid={data.grow.childImageSharp.fluid} alt={`list example`} />
          </div>
          <p>
            When you look at the list of the types of influencers, is your first
            thought that you should, of course, steer towards mega-influencers?
            After all, these influencers have a bigger audience and further
            reach; shouldn't that be what you want for your company? 
          </p>
          <p>
            While there is truth to that, there are undeniable benefits to
            utilizing micro and even nano-influencers. There are also inherent
            challenges that come with this. 
          </p>
          <h3 className="mt-10 mb-4">
            <span className="text-green text-2xl">+</span>The Benefits of
            Utilizing Nano or Micro-Influencers
          </h3>
          <p>
            Many of the benefits of utilizing nano or micro-influencers are
            similar. Therefore, we will cover both of these types of influencers
            together. 
          </p>
          <p>
            When you use nano or micro-influencers, you are reaching out to
            their specific audience. Because nano and micro-influencers are very
            focused on a specific niche, their followers tend to go beyond
            casual and are more connected to this type of influencer. 
          </p>
          <p>
            They have very committed and loyal followers. This means that by
            utilizing smaller influencers, you are likely to get a{" "}
            <strong>higher level of engagement</strong>. 
          </p>
          <p>
            Another thing that is important to note is{" "}
            <strong>authenticity</strong>. When you look at Gen Z and
            Millenials,{" "}
            <a
              target="_blank"
              className="underline text-blue"
              href="https://www.danacommunications.com/generation-z-vs-Millennials/"
            >
              63 percent
            </a>{" "}
            of Gen Z and 37 percent of Millenials prefer hearing from a "real
            person" versus a celebrity. 
          </p>
          <p>
            The authenticity you get from a nano, or a micro-influencer can't be
            beaten. Another advantage of using a nano or a micro-influencer is{" "}
            <strong>the cost</strong>. Typically, you are going to spend less
            when you utilize micro-influencers versus a mega or
            macro-influencer. 
          </p>
          <h3 className="mt-10 mb-4">
            <span className="text-2xl text-red-700">-</span>The Disadvantages of
            Utilizing Nano or Micro-Influencers
          </h3>
          <p>
            The most significant difference you will see when it comes to nano
            or micro-influencers and the disadvantages is the experience level.
            Micro-influencers have typically been on the scene longer and built
            up a larger following. 
          </p>
          <p>
            Beyond that, both styles of influencers have a similar demographic. 
          </p>
          <p>
            This type of influencer can be <strong>difficult to find</strong>.
            You may have to do a lot of research in order to find the right nano
            or micro-influencer for your product. 
          </p>
          <p>
            In addition, nano or micro-influencers are going to have a{" "}
            <strong>smaller reach</strong>. Where your reach will be wide with
            mega or macro-influencers, you will target a smaller audience when
            utilizing a nano or micro-influencer. 
          </p>
          <h3>
            <span className="text-green text-2xl">+</span>The Benefits of
            Utilizing a Mega or Macro-Influencers
          </h3>
          <p>
            When you start to dive into social media marketing with mega or
            macro-influencers, the game changes. Both of these influencers have
            big audiences. 
          </p>
          <p>
            This means you are going to <strong>get a lot of exposure</strong>{" "}
            by utilizing these influencers. In addition, both mega and
            macro-influencers are used to working with brands. You will get a{" "}
            <strong>higher level of professionalism</strong> from these
            influencers that you may not see with less experienced influencers. 
          </p>
          <p>
            With both of these influencers, you also have the ability to build
            more <strong>brand awareness</strong>. With macro-influencers, you
            can even target your campaign a little more than you can with
            mega-influencers. However, that is the biggest difference between
            the two when discussing benefits. 
          </p>
          <h3 className="mt-10 mb-4">
            <span className="text-2xl text-red-700">-</span> What Are the
            Disadvantages of Utilizing Mega or Macro-Influencers
          </h3>
          <p>
            The most significant difference when it comes to mega and
            macro-influencers and the disadvantage has to do with a{" "}
            <strong>targeted campaign</strong>. While you can run a more
            targeted campaign with macro-influencers, you begin to lose this
            ability with mega-influencers. 
          </p>
          <p>
            However, beyond that, these two levels of influencers have very
            similar disadvantages. The huge disadvantage you will see is{" "}
            <strong>the cost</strong>. When you utilize a mega or a
            macro-influencer, you will pay a hefty price. 
          </p>
          <p>
            Another disadvantage to using this level of influencer is the{" "}
            <strong>engagement rate</strong>. While nano or micro-influencers
            tend to have a high engagement rate, you see engagement rate with
            mega or macro-influencers decrease. 
          </p>
          <div className="mx-auto w-full md:w-1/2">
            <Img
              fluid={data.phone.childImageSharp.fluid}
              alt={`list example`}
            />
          </div>
          <h2 className="md:text-4xl">What's the Answer?</h2>
          <p>
            Should you use micro-influencer marketing or shoot for the sky with
            a mega or macro-influencer? The answer is:{" "}
            <span className="italic">it depends.</span> 
          </p>
          <p>
            At the end of the day, the influencer you choose will be dependent
            on your goal. Before you choose the level of influencer you want to
            use, ask yourself the following question: 
          </p>
          <p>
            <span className="text-xl text-blue font-semibold">
              Who are you selling your product to? 
            </span>
          </p>
          <p>
            For a product with a larger audience, a mega or macro-influencer may
            be the right level. You need to reach a lot of people, and they can
            help you accomplish that. 
          </p>
          <p>
            However, if you want to target a specific niche or group of people,
            your best bet is utilizing a nano or micro-influencer. These
            influencers are going to be the ones who are directly speaking to
            the audience you want to engage and will help get you results within
            your niche. 
          </p>
          <h2>Does Influencer Marketing Replace SEO? </h2>
          <p>
            What about SEO? If you're spending money on influencer marketing, do
            you need to bother with SEO? 
          </p>
          <p>
            The answer to this is a very loud YES! Micro-influencer marketing{" "}
            <strong>will not</strong> replace SEO or brand building; however, it
            is a great way to build and expand upon on it. 
          </p>
          <p>
            To better understand influencers and SEO, you need to know the ways
            that influencer marketing can build on SEO. 
          </p>
          <div>
            <div className="mx-auto my-16 flex flex-col md:flex-row items-center">
              <div className="md:w-2/3">
                <h3 className="text-center">Boost Engagement and Visibility</h3>
                <p className="mb-2">
                  Utilizing influencers for marketing helps to boost your
                  visibility. While using a mega or macro-influencer has the
                  most potential to increase your visibility, you are still
                  increasing your visibility through nano and micro-influencers;
                  your audiences are simply more targets. 
                </p>
                <p className="mb-2">
                  Nano and micro-influencer have more engaged audiences. Because
                  your influencers have a more engaged audience, you will have
                  the ability to boost your engagement in those circles. 
                </p>
                <p className="mb-2">
                  This allows you to be heard over your competitors within these
                  communities. As you become more visible and engage your
                  audience, you will drive more traffic to your website. 
                </p>
                <p className="mb-2">
                  Once you drive the traffic to your website, it's about keeping
                  them there. Different components of your SEO can help with
                  that. 
                </p>
              </div>
              <div className="mx-auto w-full md:w-1/3">
                <Img
                  fluid={data.engage.childImageSharp.fluid}
                  alt={`chatting`}
                />
              </div>
            </div>
            <div className="mx-auto w-2/3 my-16 flex flex-col md:flex-row  items-center">
              <div className="mx-auto w-full md:w-1/3">
                <Img
                  fluid={data.research.childImageSharp.fluid}
                  alt={`research`}
                />
              </div>
              <div className="md:w-2/3">
                <h3 className="text-center">Build Inbound Links</h3>
                <p>
                  Links are king, and with the help of a nano or a
                  micro-influencer, you can build inbound links. Remember,
                  though; younger generations are looking for authenticity. 
                </p>
                <p>
                  Instead of using your influencer to create a "commercial" and
                  push a product, use them to convey a message or discuss an
                  issue. They can utilize your site as a resource with the
                  content you have built there as a part of an SEO campaign. 
                </p>
                <p>
                  This comes down to both of you providing benefits to each
                  other. Your influencer will help you to create new content and
                  get your name out there, but you will also help them get seen
                  as they discuss issues relating to your niche. 
                </p>
              </div>
            </div>
            <div className="mx-auto md:w-2/3 my-16">
              <h3>Gain Social Traction</h3>
              <p>
                <a
                  target="_blank"
                  className="underline text-blue"
                  href="https://www.searchenginejournal.com/social-media-seo/196185/#close"
                >
                  Social media and SEO
                </a>{" "}
                can go hand in hand when discussing off-page SEO. Utilizing
                social media can help your company bring in more traffic in
                various formats. 
              </p>
              <p>
                Leveraging your relationship with a micro-influencer will help
                you build your social media following and social traction. When
                you're utilizing an influencer, you can do this through hashtags
                that point users in the direction you want them to go. 
              </p>
              <div className="mx-auto w-full md:w-1/2">
                <Img fluid={data.scale.childImageSharp.fluid} alt={`rocket`} />
              </div>
            </div>
          </div>
          <h2 className="md:text-4xl mt-24">
            Find Micro-Influencers With Ease
          </h2>
          <p>
            How do you find nano or micro-influencers? We mentioned above that
            this is the toughest type of influencer to find. Because they are
            not as well-known, you have to do more digging and more research. 
          </p>
          <h3>How to Find Nano or Micro-Influencers</h3>
          <p>
            There are several ways you can find nano or micro-influencers. One
            of the first things you can do is find your influencers from your
            followers. They are already following you and like your brand, which
            means this is a natural place to look. 
          </p>
          <p>
            You could also search utilizing hashtags. Many creators will utilize
            hashtags, and that can help you find the influencers who are
            creating content in the niche you are shooting for. 
          </p>
          <p>
            In addition, you can consult previous influencers or check out your
            recent likes and comments. You can often find some great choices in
            these places. However, ultimately you are going to need to do a lot
            of work to vet your potential influencers when you utilize this
            process. 
          </p>
          <h3>The Best Way to Find Nano or Micro-Influencers</h3>
          <p>
            If you want to find an influencer but do not want the hassle of
            doing a deep dive, you can utilize a platform that provides
            information on influencers.  We at{" "}
            <Link className="underline text-green" to="/">
              The Influencer List
            </Link>{" "}
            have created a list of over 1,000 nano and micro-influencers. This
            list is created based on the individual influencers merit and
            metrics. 
          </p>
          <p>
            This list will help you find the influencer you want in the niche
            you want quickly. 
          </p>
          <h2>Get Started With Micro-Influencer Marketing</h2>
          <p>
            Are you ready to start growing your brand through nano or
            micro-influencer marketing? This style of marketing comes with
            unique benefits that will boost your company. 
          </p>
          <p className="mb-32">
            Find the right influencer for you through our Influencer List today.
            You can{" "}
            <Link className="underline text-green" to="/pricing/">
              buy it now
            </Link>{" "}
            . 
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default InfluncerMarketingArticle
